import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Flex } from '@rebass/grid';
import { FaChevronLeft } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md';
import Page from '../../components/Page';
import resume from '../../assets/JeremyOrtizResume.pdf';
// Might need to change the way I export the above, I cant remember why I said this lol

const ResumeContainer = styled.div`
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   grid-row-gap: 3rem;
   margin: auto;
   max-width: 90%;
   padding: 4rem 0;
   margin-top: 6rem;

   @media (min-width: 768px) {
      grid-template-columns: 1fr 3fr;
      grid-row-gap: 5rem;
      max-width: 60%;
   }
`;

const Header = styled.h1`
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0;
   font-family: 'Roboto', sans-serif;
   text-align: center;
   font-size: 2.4rem;
   text-transform: uppercase;
   color: #b20e66;

   @media (min-width: 768px) {
      font-size: 3em;
   }
`;

const RoleWrapper = styled(Flex)`
   justify-content: space-between;

   p {
      margin-bottom: 0;
   }
`;

const CompanyName = styled.h4`
   margin: 0;
   font-family: 'Roboto', sans-serif;
   text-transform: uppercase;
`;

const Category = styled.h2`
   margin: 0;
   font-family: 'Roboto', sans-serif;
   text-transform: uppercase;
   color: #b20e66;
`;

const Experience = styled.div`
   margin-bottom: 4rem;
`;

const SkillsContainer = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;

   p {
      margin: 0;
   }
`;

const SkillCategory = styled.span`
   font-weight: bold;
`;

const Title = styled.p`
   font-weight: bold;
`;

const NavBar = styled(Flex)`
   position: fixed;
   top: 0;
   display: grid;
   grid-template-columns: 1fr 2fr 1fr;
   grid-template-rows: 6rem;
   width: 100%;
   padding: 0 1rem;
   background: white;
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
   text-transform: uppercase;
   /* might remove the above box shadow? */

   @media (min-width: 768px) {
      padding: 0 4rem;
   }
`;

const HomeLink = styled(Link)`
   display: flex;
   align-items: center;
   transition: all 0.15s;
   text-decoration: none;
   font-size: 2rem;
   font-weight: bold;
   color: #cc1075;

   &:hover {
      color: #b20e66;
   }

   &:active {
   }
`;

const DownloadLink = styled.a`
   display: flex;
   align-items: center;
   justify-content: flex-end;
   text-decoration: none;
   font-size: 2rem;
   font-weight: bold;
   color: #cc1075;

   &:hover {
      color: #b20e66;
   }

   &:active {
   }
`;

const LeftChevron = styled(FaChevronLeft)`
   margin-right: 1rem;
   font-size: 2.4rem;
`;

const DlIcon = styled(MdFileDownload)`
   font-size: 2.4rem;
`;

const StyledLink = styled.a`
   text-decoration: none;
   color: #cc1075;

   &:hover {
      text-decoration: underline;
      color: #b20e66;
   }
`;

// need to fix spacing
// Do i need to put the h1,h2 in boxes also need to check if h2 is correct to use
// probably need to fix the spacing in skills section, it should all be within the same box
export const Resume = () => (
   <Page>
      <NavBar>
         <HomeLink to={{ pathname: '/', state: { prev: false } }}>
            <LeftChevron />
         </HomeLink>

         <Header>Jeremy Ortiz</Header>

         <DownloadLink href={resume} title="Download Resume" download>
            <DlIcon />
         </DownloadLink>
      </NavBar>

      <ResumeContainer>
         <Category>Skills</Category>
         <SkillsContainer>
            <p>
               <SkillCategory>OS:</SkillCategory> Windows, MacOS, Linux
               <br />
               <SkillCategory>Tools:</SkillCategory> Jira, Confluence
               <br />
               <SkillCategory>Process/Business:</SkillCategory> Agile, Scrum,
               Project Management
               <br />
               <SkillCategory>Content Management Systems:</SkillCategory>
               WordPress, Webflow
               <br />
               <SkillCategory>Version Control System:</SkillCategory> Git
               <br />
            </p>
            <p>
               <SkillCategory>Languages:</SkillCategory> JavaScript, HTML, CSS,
               Python, Bash, SQL
               <br />
               <SkillCategory>Frameworks:</SkillCategory> React, Redux, Node.js,
               Express.js, Grunt, jQuery, LESS, Swagger
               <br />
               <SkillCategory>Testing:</SkillCategory> Jest, Enzyme
               <br />
               <SkillCategory>DevOps:</SkillCategory> Docker, AWS, Bamboo,
               BitBucket Pipelines
               <br />
            </p>
         </SkillsContainer>
         <Category>Experience</Category>
         <div>
            <Experience>
               <CompanyName>SocietyOne</CompanyName>
               <RoleWrapper>
                  <Title>Front End Developer</Title>
                  <p>February 2019 - Present</p>
               </RoleWrapper>
               <p>
                  Tasked with rebuilding the public site in Webflow CMS to allow
                  for marketing to make changes without the need for a developer
               </p>
            </Experience>
            <Experience>
               <CompanyName>Data Republic</CompanyName>
               <RoleWrapper>
                  <Title>Front End Developer</Title>
                  <p>September 2018 - Present</p>
               </RoleWrapper>
               <p>
                  Setup and built new company website in WordPress. Developed
                  custom theme and components using HTML, CSS, PHP, Grunt.
                  Responsible for new website administration and maintenance.
                  Developed UI POC’s in React and Node.js Created bash script to
                  setup new company laptops. Developed python script to sync
                  data between product database and salesforce
               </p>
               <p>
                  Live Work:{' '}
                  <StyledLink href="https://www.datarepublic.com/">
                     Data Republic
                  </StyledLink>
               </p>
            </Experience>
            <Experience>
               <CompanyName>Westpac</CompanyName>
               <RoleWrapper>
                  <Title>
                     Software Developer | Westpac Digital Platform UI
                  </Title>
                  <p>February 2018 - August 2018</p>
               </RoleWrapper>
               <p>
                  Maintained and updated Westpac React project starter code.
                  Designed starter project API using Swagger specification.
                  Developed and maintained API starter code in Node.js and
                  Express.js. Designed and developed an automatic onboarding
                  portal in React for new UI projects. Designed and developed an
                  API as a microservice to the onboarding UI to use the Jira and
                  BitBucket API’s as part of the workflow.
               </p>
               <RoleWrapper>
                  <Title>
                     Associate Project Manager | Customer Contact Centre
                     Technolgy
                  </Title>
                  <p>August 2017 - January 2018</p>
               </RoleWrapper>
               <p>
                  Managed multiple projects {`< 250k`} from initiation to
                  delivery. Engaged and managed expectations of key
                  stakeholders. Delivered projects using waterfall and hybrid
                  methodologies. Liaised with development and business teams to
                  develop project timelines that meet business requirements.
                  Responsible for project forecasting and resource management to
                  ensure resources were available to work on projects and that
                  the projects stayed within budget.
               </p>
               <RoleWrapper>
                  <Title>Front End Developer</Title>
                  <p>February 2017 - July 2017</p>
               </RoleWrapper>
               <p>
                  Developed UI components in HTML, CSS & JavaScript for the St
                  George Banking Group (stgeorge.com.au, bankofmelbourne.com.au,
                  banksa.com.au) public websites as part of an entire site
                  redesign. Developed UI components to be accessible/WCAG
                  compliant, use mobile first/responsive design and multi-brand
                  via LESS and Grunt. Worked with designers to ensure final
                  designs are usable and accessible before development.
               </p>
               <p>
                  Live Work:{' '}
                  <StyledLink href="https://www.stgeorge.com.au/">
                     St George
                  </StyledLink>
                  ,{' '}
                  <StyledLink href="https://www.bankofmelbourne.com.au/">
                     Bank of Melbourne
                  </StyledLink>
                  ,{' '}
                  <StyledLink href="https://www.banksa.com.au/">
                     Bank SA
                  </StyledLink>
               </p>
               <RoleWrapper>
                  <Title>Group Technology Graduate</Title>
                  <p>February 2017 - Present</p>
               </RoleWrapper>
               <p>
                  Currently in my last 6 month rotation as part of a 2 year
                  graduate program.
               </p>
            </Experience>
         </div>
         <Category>Education</Category>
         <div>
            <CompanyName>University of New South Wales</CompanyName>
            <RoleWrapper>
               <p>B.S. Computer Science</p>
               <p>March 2013 - December 2015</p>
            </RoleWrapper>
         </div>
      </ResumeContainer>
   </Page>
);
