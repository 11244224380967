import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import './index.css';
import * as serviceWorker from './serviceWorker';

import Routes from './pages/Routes';

const supportsHistory = 'pushState' in window.history;

const App = () => (
   <Router forceRefresh={!supportsHistory}>
      <ThemeProvider theme={theme}>
         <div>
            <Routes />
         </div>
      </ThemeProvider>
   </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
