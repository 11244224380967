import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Home } from './Home';
import { Resume } from './Resume';
// import { About } from "./About";
// import { Portfolio } from "./Portfolio";

const Routes = () => (
   <Route
      render={({ location }) => {
         const { pathname } = location;
         return (
            <TransitionGroup>
               <CSSTransition
                  key={pathname}
                  classNames="page"
                  timeout={{ enter: 500, exit: 500 }}
               >
                  <Route
                     location={location}
                     render={() => (
                        <Switch location={location}>
                           {/* <Route exact path="/" component={Home} /> */}
                           {/* <Route path="/about" component={About} /> */}
                           {/* <Route path="/portfolio" component={Portfolio} /> */}
                           <Route path="/resume" component={Resume} />
                           <Route component={Home} />
                        </Switch>
                     )}
                  />
               </CSSTransition>
            </TransitionGroup>
         );
      }}
   />
);

export default Routes;
