import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Flex } from '@rebass/grid';

export const Content = styled(Flex)`
   position: relative;
   flex-direction: column;
   margin: 0 8% 4% auto;
   min-height: 60vh;
   padding: 1% 25% 0 1%;

   @media (min-width: 768px) {
   }
`;

export const GradientBG = styled.div`
   position: absolute;
   top: 0;
   min-height: 100vh;
   width: 65%;
   background: linear-gradient(
      170deg,
      #fdc6ef,
      #edc6ef,
      #ddc6ef,
      #cdc6ef,
      #bdc6ef
   );

   @media (min-width: 768px) {
      width: 65%;
   }
`;

export const ContentWrapper = styled(Flex)`
   flex-direction: column;
   justify-content: center;
   min-height: 100vh;

   @media (min-width: 768px) {
      justify-content: column;
   }
`;

export const GreyBG = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   background-color: #f5f5f5;

   @media (min-width: 768px) {
      height: 86%;
      top: 14%;
      width: 85%;
   }
`;

export const TextWrapper = styled.div`
   z-index: 1;
   flex-grow: 1;
   text-align: left;
   color: #b20e66;
`;

export const Header = styled.h1`
   z-index: 1;
   margin-top: 0;
   margin-bottom: 0;
   text-transform: uppercase;
   font-family: 'Roboto', sans-serif;
   font-size: 5rem;
   line-height: 1;
   background: linear-gradient(to bottom, #ff1493, #e51284, #cc1075, #b20e66);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;

   @media (min-width: 768px) {
      font-size: 16rem;
   }
`;

export const ResumeLink = styled(Link)`
   position: absolute;
   bottom: 25%;
   display: inline-block;
   transition: all 0.15s;
   box-sizing: border-box;
   font-size: 24px;
   text-decoration: none;
   text-transform: uppercase;
   color: #b20e66;
   padding: 0.5rem 0.5rem;
   z-index: 1;
   &:hover {
      padding-right: 3rem;
      background: #b20e66;
      color: #f5f5f5;
   }

   &:active {
      background: #ff1493;
   }

   @media (min-width: 768px) {
      right: 10%;
   }
`;

export const IconLink = styled.a`
   margin: 0px 16px;
   font-size: 5rem;
   color: #d8d8d8;

   &:hover {
      color: #b20e66;
   }

   &:active {
      color: #ff1493;
   }
`;

export const IconWrapper = styled.div`
   z-index: 1;
`;

export const SubHeading = styled.p`
   font-weight: bold;
   font-size: 1.8rem;

   @media (min-width: 768px) {
      font-size: 2.4rem;
   }
`;

export const Description = styled.p`
   font-size: 1.4rem;

   @media (min-width: 768px) {
      font-size: 1.8rem;
   }
`;

export const Divider = styled.span`
   display: block;
`;
