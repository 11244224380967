import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
   height: 100vh;
   overflow-y: auto;
   position: fixed;
   top: 0px;
   width: 100%;
   -webkit-overflow-scrolling: touch;
   transition: transform 0.5s linear;
   font-size: 1.6em;
   &.page-enter {
      transform: translate(-100%, 0);
   }

   &.page-enter-active {
      transform: translate(0, 0);
   }

   &.page-exit {
      transform: translate(-100%, 0);
   }

   &.page--prev.page-enter {
      transform: translate(100%, 0);
   }

   &.page--prev.page-enter-active {
      transform: translate(0, 0);
   }

   &.page--prev.page-exit {
      transform: translate(100%, 0);
   }
`;

// there needs to be a better way to do this classnames crap
const Page = ({ children, location: { state } }) => {
   const cx = classNames({
      page: true,
      'page--prev': state && state.prev
   });
   return <Wrapper className={cx}>{children}</Wrapper>;
};

// export default Page;

// const Page = ({ children }) => <section>{children}</section>;

export default withRouter(Page);
