import React from 'react';
import { FaGithub, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';
import Page from '../../components/Page';
import {
   Content,
   GradientBG,
   ContentWrapper,
   GreyBG,
   Header,
   Divider,
   TextWrapper,
   SubHeading,
   Description,
   IconWrapper,
   IconLink,
   ResumeLink
} from './style';

export const Home = () => (
   <Page>
      <GradientBG />
      <ContentWrapper>
         <Content>
            <GreyBG />
            <Header>
               Jeremy <Divider>Ortiz</Divider>
            </Header>
            <TextWrapper>
               <SubHeading>
                  Front End Developer | Javascript | React | Node.js
               </SubHeading>
               <Description>
                  I'm currently redesigning and rebuilding this website to bring
                  you a new and exciting experience so stay tuned!
               </Description>
            </TextWrapper>
            <ResumeLink to={{ pathname: '/resume', state: { prev: true } }}>
               View Resume
            </ResumeLink>
            <IconWrapper>
               <IconLink href="https://www.linkedin.com/in/jeremy-ortiz-228413115/">
                  <FaLinkedinIn />
               </IconLink>
               <IconLink href="https://github.com/jaortiz">
                  <FaGithub />
               </IconLink>
               <IconLink href="mailto:jeremy@jeremyortiz.io">
                  <FaEnvelope />
               </IconLink>
            </IconWrapper>
         </Content>
      </ContentWrapper>
   </Page>
);
